<template>
  <!-- <transition name="fade"> -->
  <span class="error" v-if="error">{{ error }}</span>
  <!-- </transition> -->
</template>
<script>
export default {
  props: {
    error: { type: String, default: null },
  },
};
</script>
<style scoped>
.error {
  display: block;
  text-align: center;
  color: red;
  margin-bottom: 7px;
  font-size: 14px;
  @media (max-width: 440px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
/* .fade-enter-active {
  animation: fade-in 5s;
}
.fade-leave-active {
  animation: fade-out 5s;
}
@keyframes fade-in {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;

  }
}
@keyframes fade-out {
  0% {
    top: 80px;
  }
  100% {
    top: -40px;
  }
} */
</style>

